import React, { useState } from 'react';
import axios from 'axios';
import { Button, CircularProgress, Paper } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { useAuth } from 'containers/AuthProvider';
import LoginForm from './LoginForm';
import gmLogo from 'images/gm-logo.png';

const apiUrl = process.env.REACT_APP_API_URL;
const api = axios.create({ baseURL: apiUrl });
const instanceName = process.env.REACT_APP_GM_INSTANCE_NAME;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    main: {
      display: 'flex',
      height: '100vh',
      width: '100vw',
      alignItems: 'center',
      justifyContent: 'center',
      padding: theme.spacing(4),
    },
    paper: {
      display: 'flex',
      flexWrap: 'wrap',
      maxWidth: 580,
      overflow: 'hidden',
      flex: '1 1',
    },
    formContainer: {
      padding: theme.spacing(2),
      display: 'flex',
      flex: '1 1 345px',
      minWidth: '235px',
      flexDirection: 'column',
    },
    image: {
      background: `url(${gmLogo})`,
      backgroundSize: 'contain',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center',
      minHeight: 235,
      minWidth: 235,
      flex: '1 0 235px',
      backgroundColor: '#25579c',
    },
    buttons: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    instanceName: {
      ...theme.typography.caption,
      color: theme.palette.text.hint,
    },
    loadingContainer: {
      position: 'relative',
    },
    loading: {
      position: 'absolute',
      left: '25px',
    },
    title: {
      ...theme.typography.h5,
      margin: 0,
      marginBottom: theme.spacing(2),
    },
  })
);

function LoginPage() {
  const { login } = useAuth();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<null | string>(null);
  const styles = useStyles();

  async function handleLogin(username: string, password: string) {
    try {
      setError(null);
      const {
        data: {
          accessToken,
          refreshToken,
          role,
          areSoonToExpire,
          areExpired,
          credentialsExpireAt,
          dealerGroupId,
        },
      } = await api.post('/auth/login', {
        username,
        password,
      });
      await api('/visits/save', {
        method: 'POST',
        headers: { Authorization: `Bearer ${accessToken}` },
        data: accessToken,
      });
      setError(null);
      login(
        role,
        accessToken,
        refreshToken,
        username,
        dealerGroupId,
        areSoonToExpire,
        areExpired,
        credentialsExpireAt
      );
    } catch (error) {
      if (error.response) {
        if (error.response.status === 401) {
          setError('Usuario o contraseña incorrectos');
        } else {
          setError('Algo salió mal mientras hacias login. Intenta más tarde.');
        }
      } else {
        setError('No se pudo contactar al servidor. Revisa tu conexión.');
      }
      setLoading(false);
    }
  }
  return (
    <main className={styles.main}>
      <Paper className={styles.paper}>
        <div className={styles.image} />
        <div className={styles.formContainer}>
          <h5 className={styles.title}>Login</h5>
          {error && <Alert severity="error">{error}</Alert>}
          <LoginForm
            onSubmit={(username, password) => handleLogin(username, password)}
            renderButtons={() => (
              <div className={styles.buttons}>
                <span className={styles.instanceName}>{instanceName}</span>
                <div className={styles.loadingContainer}>
                  {loading && (
                    <CircularProgress className={styles.loading} size={32} />
                  )}
                  <Button
                    type="submit"
                    size="large"
                    variant="outlined"
                    disabled={loading}
                  >
                    Login
                  </Button>
                </div>
              </div>
            )}
          />
        </div>
      </Paper>
    </main>
  );
}

export default LoginPage;
