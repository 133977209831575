import React from 'react';
import MaterialTable from 'material-table';
import { debounce } from 'lodash';
import {
  Paper,
  TextField,
  InputAdornment,
  Box,
  Link,
  Button,
} from '@material-ui/core';
import { Search, ReplaySharp } from '@material-ui/icons';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { useTasksTable } from './reducer';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    top: {
      padding: theme.spacing(3),
      display: 'flex',
      flexDirection: 'column',
    },
    pageTitle: {
      ...theme.typography.h4,
      margin: 0,
      marginBottom: theme.spacing(2),
    },
    tableContainer: {
      display: 'flex',
      justifySelf: 'stretch',
      flexDirection: 'column',
    },
    table: {
      boxShadow: 'none',
    },
    formControl: {
      marginRight: theme.spacing(2),
      minWidth: 120,
    },
    search: {
      minWidth: 160,
    },
    filters: {
      display: 'flex',
      padding: theme.spacing(2),
      paddingBottom: 0,
    },
    spacer: {
      flexGrow: 1,
    },
  })
);

const statusBadgeProps = {
  borderRadius: '16px',
  padding: '4px 8px',
  textAlign: 'center',
};

const tableColumns = [
  {
    title: 'Id',
    field: 'id',
    width: 80,
  },
  {
    title: 'Estado',
    field: 'status',
    width: 126,
    render: (data: any) => {
      switch (data.status) {
        case 'PENDIENTE':
          return (
            <Box {...statusBadgeProps} bgcolor="info.main" color="white">
              Pendiente
            </Box>
          );
        case 'EJECUTANDO':
          return (
            <Box {...statusBadgeProps} bgcolor="warning.main">
              Ejecutando
            </Box>
          );
        case 'COMPLETADA':
          return (
            <Box
              {...statusBadgeProps}
              color="white"
              bgcolor={data.errorsFilePath ? 'error.main' : 'success.main'}
            >
              {data.errorsFilePath ? 'Con errores' : 'Completada'}
            </Box>
          );
      }
    },
  },
  {
    title: 'Nombre del archivo',
    field: 'filePath',
  },
  {
    title: 'Creado',
    field: 'createdAt',
  },
  {
    title: 'Actualizado',
    field: 'updatedAt',
  },
  {
    title: 'Errores',
    field: 'errorsFilePath',
    render: (data: any) =>
      data.errorsFilePath && (
        <Link href={data.errorsFilePath} rel="external" target="_blank">
          Ver Errores
        </Link>
      ),
  },
];

function LiveStoreTasks() {
  const styles = useStyles();
  const {
    loading,
    tasks,
    updateQuery,
    query,
    reload,
    hasDelayedTasks,
    markAsPending,
  } = useTasksTable();
  const debouncedUpdateSearch = React.useCallback(
    debounce((search: string) => updateQuery('search', search), 300),
    []
  );
  return (
    <section className={styles.top}>
      <h3 className={styles.pageTitle}>Tareas Pendientes</h3>
      <Paper className={styles.tableContainer}>
        <div className={styles.filters}>
          <Button endIcon={<ReplaySharp />} onClick={reload} disabled={loading}>
            Actualizar
          </Button>
          <div className={styles.spacer} />
          <TextField
            className={styles.search}
            variant="outlined"
            label="Buscar"
            defaultValue={query.search}
            onChange={(e) => debouncedUpdateSearch(e.target.value)}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <Search />
                </InputAdornment>
              ),
            }}
          />
        </div>

        <MaterialTable
          style={{ boxShadow: 'none' }}
          options={{
            toolbar: false,
            paging: false,
            actionsColumnIndex: 7,
          }}
          data={tasks}
          columns={tableColumns}
          isLoading={loading}
          actions={
            // Show this only for delayed tasks and only for administrators
            hasDelayedTasks
              ? [
                  (rowData) => ({
                    icon: 'replay',
                    tooltip: 'Marcar como pendiente',
                    hidden: !rowData.isDelayed,
                    onClick: () => {
                      markAsPending(rowData.id);
                    },
                  }),
                ]
              : undefined
          }
          localization={{
            header: {
              actions: 'Acciones',
            },
            body: {
              emptyDataSourceMessage: 'No hay tareas para mostrar',
            },
          }}
        />
        {/* page for TablePagination is 0 based so we have to transalate that */}
        {/*  <TablePagination
          component="div"
          page={query.page - 1}
          rowsPerPage={5}
          rowsPerPageOptions={[5, 10, 20]}
          count={1}
          onChangePage={(e, page) => updateQuery('page', page + 1)}
          onChangeRowsPerPage={(e) => updateQuery('size', e.target.value)}
        /> */}
      </Paper>
    </section>
  );
}

export default LiveStoreTasks;
