import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import CssBaseline from '@material-ui/core/CssBaseline';
import { ThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import esLocale from 'date-fns/locale/es';
import DateFnsUtils from '@date-io/date-fns';
import { esES } from '@material-ui/core/locale';

import { AuthProvider } from 'containers/AuthProvider';
import { ApiProvider } from 'containers/ApiProvider';
import ErrorBoudary from 'containers/ErrorBoundary';
import Routes from './Routes';

const theme = createMuiTheme(
  {
    palette: {
      primary: {
        light: '#f3c98b',
        main: '#eba43e',
        dark: '#d08316',
        contrastText: '#fff',
      },
      secondary: {
        light: '#8f8f92',
        main: '#45454a',
        dark: '#000000',
        contrastText: '#fff',
      },
    },
    overrides: {
      MuiCssBaseline: {
        '@global': {
          body: {
            '& *::-webkit-scrollbar': {
              width: 8,
              height: 8,
            },
            '& *::-webkit-scrollbar-track': {
              background: 'transparent',
            },
            '& *::-webkit-scrollbar-thumb': {
              background: 'rgba(224, 224, 224, 1)',
              width: 4,
              borderRadius: 8,
            },
            background: 'red',
          },
        },
      },
    },
  },
  esES
);

function App() {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <ErrorBoudary>
        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={esLocale}>
          <AuthProvider>
            <ApiProvider>
              <Router>
                <Routes />
              </Router>
            </ApiProvider>
          </AuthProvider>
        </MuiPickersUtilsProvider>
      </ErrorBoudary>
    </ThemeProvider>
  );
}

export default App;
