import React, { lazy, Suspense, useMemo } from 'react';
import {
  Backup,
  Storefront,
  Cached,
  Home as HomeIcon,
  Description,
  TrackChanges,
  HelpOutline,
  Assignment,
  FindInPage,
  Settings,
  CallEnd,
  DynamicFeed,
  Assessment,
  SupervisedUserCircle,
  Timeline,
  AssignmentInd,
  SettingsPhone,
  Today,
  Book,
  EventNote,
  EmojiPeople,
  RecentActors,
} from '@material-ui/icons';
import LoadingPlaceholder from './LoadingPlaceholder';
import { useAuth } from '../../AuthProvider';
import LiveStoreTasks from '../../../pages/Tasks/LiveStoreTasks/index';

// Import pages with lazy
const Home = lazy(() => import('pages/Home'));
const CampaignsAdmin = lazy(() => import('pages/CampaignsAdmin'));
const IgnoredCampaigns = lazy(() => import('pages/IgnoredCampaigns'));
const DirectUpload = lazy(() => import('pages/DirectUpload'));
const SalesUpload = lazy(() => import('pages/SalesUpload'));
const Tasks = lazy(() => import('pages/Tasks'));
const Models = lazy(() => import('pages/Models'));
const Segments = lazy(() => import('pages/Segments'));
const Dealers = lazy(() => import('pages/Dealers'));
const DealerCities = lazy(() => import('pages/DealerCities'));
const DealerGroups = lazy(() => import('pages/DealerGroups'));
const SourceUpload = lazy(() => import('pages/SourceUpload'));
const SalesReports = lazy(() => import('pages/SalesReports'));
const Users = lazy(() => import('pages/UsersAdmin'));
const SiebelLeads = lazy(() => import('pages/SiebelLeads'));
const SiebelReports = lazy(() => import('pages/SiebelReports'));
const Announcements = lazy(() => import('pages/Announcements'));
const DailyMeets = lazy(() => import('pages/DailyMeets'));
const Zones = lazy(() => import('pages/Zones'));
const Cities = lazy(() => import('pages/Cities'));
const SearchLeads = lazy(() => import('pages/SearchLeads'));
const LeadsByForm = lazy(() => import('pages/LeadsByForm'));
const SiebelObjectives = lazy(() => import('pages/SiebelObjectives'));
const Inxait = lazy(() => import('pages/Inxait'));
const InxaitEvents = lazy(() => import('pages/InxaitEvents'));
const Faq = lazy(() => import('pages/Faq'));
const DailyAccess = lazy(() => import('pages/DailyAccess'));
const ConsiergePanel = lazy(() => import('pages/ConsiergePanel'));
const T1Funnel = lazy(() => import('pages/DealersFunnel/Graphs/T1Funnel'));
const DdpFunnel = lazy(() => import('pages/DealersFunnel/Graphs/DdpFunnel'));
const DealersFunnelSummary = lazy(() => import('pages/DealersFunnel/Summary'));
const ExpertPanel = lazy(() => import('pages/ExpertPanel'));
const DealerCallcenter = lazy(() => import('pages/DealerCallcenter'));
const ExpertCalendars = lazy(() => import('pages/ExpertCalendars'));
const DealerSupervisor = lazy(() => import('pages/DealerSupervisor'));
const DailyLeads = lazy(() => import('pages/DailyLeads'));
const FunnelLiveStore = lazy(() => import('pages/FunnelLiveStore'));
const OverallAnalysis = lazy(() => import('pages/OverallAnalysis'));
const UploadContactability = lazy(() => import('pages/Contactability/Upload'));
const ContactabilityValidation = lazy(() =>
  import('pages/Contactability/Validation')
);
const ContactabilityAnalysis = lazy(() =>
  import('pages/Contactability/Report')
);
const LiveStoreManagement = lazy(() => import('pages/LiveStore/Management'));
const LiveStoreDealers = lazy(() => import('pages/LiveStore/Dealers'));
const LiveStoreGMF = lazy(() => import('pages/LiveStore/GMF'));
const CreateLead = lazy(() => import('pages/LiveStore/CreateLead'));
const FranchiseReport = lazy(() => import('pages/FranchiseReport'));
const SurveyBase = lazy(() => import('pages/Contactability/SurveyBase'));
const LeadsPerCountry = lazy(() => import('pages/LeadsPerCountry'));
const VirtualExperience = lazy(() =>
  import('pages/LiveStore/VirtualExperience')
);

const instance = process.env.REACT_APP_GM_INSTANCE_SLUG;
let isChileOrPeru = false;

String(instance) === 'ec' ||
String(instance) === 'co' ||
String(instance) === 'py' ||
String(instance) === 'uy'
  ? (isChileOrPeru = false)
  : (isChileOrPeru = true);

export type SimpleRoute = {
  name: string;
  pathname: string;
  icon?: React.ReactElement;
  page: React.ReactElement;
  roles?: string[];
};

export type CompoundRoute = {
  name: string;
  pathname: string;
  icon: React.ReactElement;
  subroutes: SimpleRoute[];
  roles?: string[];
};

function CreateRoutes() {
  const { role } = useAuth();

  return [
    {
      name: 'Inicio',
      pathname: '/home',
      icon: <HomeIcon />,
      roles: ['admin', 'dealer', 'gm'],
      page: (
        <Suspense fallback={<LoadingPlaceholder />}>
          <Home />
        </Suspense>
      ),
    },
    {
      name: 'Panel del Conserje',
      pathname: '/consierge',
      icon: <SupervisedUserCircle />,
      roles: ['admin'],
      page: (
        <Suspense fallback={<LoadingPlaceholder />}>
          <ConsiergePanel />
        </Suspense>
      ),
    },
    {
      name: 'Dealer CallCenter',
      pathname: '/dealer-callcenter',
      icon: <SettingsPhone />,
      roles: isChileOrPeru
        ? ['admin', 'callcenter_dealer', 'supervisor_dealer']
        : ['admin', 'dealer', 'callcenter_dealer', 'supervisor_dealer'],
      page: (
        <Suspense fallback={<LoadingPlaceholder />}>
          <DealerCallcenter />
        </Suspense>
      ),
    },
    {
      name: 'Dealer Supervisor',
      pathname: '/dealer-supervisor',
      icon: <Book />,
      roles: isChileOrPeru
        ? ['admin', 'supervisor_dealer', 'callcenter_dealer']
        : ['admin', 'dealer', 'supervisor_dealer', 'callcenter_dealer'],
      page: (
        <Suspense fallback={<LoadingPlaceholder />}>
          <DealerSupervisor />
        </Suspense>
      ),
    },
    {
      name: 'Panel del Experto',
      pathname: '/expert-panel',
      icon: <AssignmentInd />,
      roles: ['admin', 'autotrain'],
      subroutes: [
        {
          name: 'Panel del Experto',
          pathname: '/expert-panel/panel',
          page: (
            <Suspense fallback={<LoadingPlaceholder />}>
              <ExpertPanel />
            </Suspense>
          ),
        },
        {
          name: 'Citas',
          pathname: '/expert-panel/meets',
          page: (
            <Suspense fallback={<LoadingPlaceholder />}>
              <DailyMeets />
            </Suspense>
          ),
        },
      ],
    },
    ...(instance === 'cl'
      ? [
          {
            name: 'Live Store',
            pathname: '/live-store',
            icon: <EmojiPeople />,
            roles: ['admin', 'gm', 'dealer', 'nodo', 'inxait', 'autotrain'],
            subroutes: [
              ...(String(role) === 'admin' ||
              String(role) === 'nodo' ||
              String(role) === 'inxait' ||
              String(role) === 'gm'
                ? [
                    {
                      name: 'Crear Lead',
                      pathname: '/live-store/create-lead',
                      page: (
                        <Suspense fallback={<LoadingPlaceholder />}>
                          <CreateLead
                            onModelCreated={(message: string) => {}}
                            baseUrl="/live-store/management"
                          />
                        </Suspense>
                      ),
                    },
                  ]
                : []),
              ...(String(role) === 'admin' ||
              String(role) === 'nodo' ||
              String(role) === 'inxait' ||
              String(role) === 'gm'
                ? [
                    {
                      name: 'Gestionar',
                      pathname: '/live-store/management',
                      page: (
                        <Suspense fallback={<LoadingPlaceholder />}>
                          <LiveStoreManagement />
                        </Suspense>
                      ),
                    },
                  ]
                : []),
              ...(String(role) === 'admin' ||
              String(role) === 'inxait' ||
              String(role) === 'gm' ||
              String(role) === 'nodo' ||
              String(role) === 'dealer'
                ? [
                    {
                      name: 'Dealers',
                      pathname: '/live-store/dealers',
                      page: (
                        <Suspense fallback={<LoadingPlaceholder />}>
                          <LiveStoreDealers />
                        </Suspense>
                      ),
                    },
                  ]
                : []),
              {
                name: 'Tareas pendientes',
                pathname: '/tasks-liveStore',
                icon: <Cached />,
                roles: ['dealer'],
                page: (
                  <Suspense fallback={<LoadingPlaceholder />}>
                    <LiveStoreTasks />
                  </Suspense>
                ),
              },
              ...(String(role) === 'admin' ||
              String(role) === 'inxait' ||
              String(role) === 'gm' ||
              String(role) === 'nodo'
                ? [
                    {
                      name: 'GMF',
                      pathname: '/live-store/gmf',
                      page: (
                        <Suspense fallback={<LoadingPlaceholder />}>
                          <LiveStoreGMF />
                        </Suspense>
                      ),
                    },
                  ]
                : []),
              ...(String(role) === 'admin' || String(role) === 'autotrain'
                ? [
                    {
                      name: 'Experiencia Virtual',
                      pathname: '/live-store/virtual-experience',
                      page: (
                        <Suspense fallback={<LoadingPlaceholder />}>
                          <VirtualExperience />
                        </Suspense>
                      ),
                    },
                  ]
                : []),
            ],
          },
        ]
      : []),
    {
      name: 'Carga de archivos',
      pathname: '/uploads',
      icon: <Backup />,
      roles: ['admin'],
      subroutes: [
        {
          name: 'Carga Directa',
          pathname: '/uploads/direct',
          page: (
            <Suspense fallback={<LoadingPlaceholder />}>
              <DirectUpload />
            </Suspense>
          ),
        },
        {
          name: 'Carga de Ventas',
          pathname: '/uploads/sales',
          page: (
            <Suspense fallback={<LoadingPlaceholder />}>
              <SalesUpload />
            </Suspense>
          ),
        },
        {
          name: 'Carga de Fuentes',
          pathname: '/uploads/source',
          page: (
            <Suspense fallback={<LoadingPlaceholder />}>
              <SourceUpload />
            </Suspense>
          ),
        },
      ],
    },
    {
      name: 'Tareas pendientes',
      pathname: '/tasks',
      icon: <Cached />,
      roles: ['admin'],
      page: (
        <Suspense fallback={<LoadingPlaceholder />}>
          <Tasks />
        </Suspense>
      ),
    },

    {
      name: 'Campañas',
      pathname: '/campaigns',
      icon: <Storefront />,
      roles: ['admin'],
      subroutes: [
        {
          name: 'Administrar',
          pathname: '/campaigns/admin',
          page: (
            <Suspense fallback={<LoadingPlaceholder />}>
              <CampaignsAdmin />
            </Suspense>
          ),
        },
        {
          name: 'Campañas ignoradas',
          pathname: '/campaigns/ignored',
          page: (
            <Suspense fallback={<LoadingPlaceholder />}>
              <IgnoredCampaigns />
            </Suspense>
          ),
        },
      ],
    },
    {
      name: 'Contactabilidad',
      pathname: '/contactability',
      icon: <RecentActors />,
      roles: ['admin', 'dealer', 'gm'],
      subroutes: [
        ...(String(role) === 'admin' || String(role) === 'gm'
          ? [
              {
                name: 'Carga',
                pathname: '/contactability/load',
                page: (
                  <Suspense fallback={<LoadingPlaceholder />}>
                    <UploadContactability />
                  </Suspense>
                ),
              },
            ]
          : []),
        {
          name: 'Validación',
          pathname: '/contactability/validation',
          page: (
            <Suspense fallback={<LoadingPlaceholder />}>
              <ContactabilityValidation />
            </Suspense>
          ),
        },
        {
          name: 'Reporte',
          pathname: '/contactability/report',
          page: (
            <Suspense fallback={<LoadingPlaceholder />}>
              <ContactabilityAnalysis />
            </Suspense>
          ),
        },
        ...(String(role) === 'admin' || String(role) === 'callcenter'
          ? [
              {
                name: 'Base Encuestas',
                pathname: '/contactability/survey-base',
                page: (
                  <Suspense fallback={<LoadingPlaceholder />}>
                    <SurveyBase />
                  </Suspense>
                ),
              },
            ]
          : []),
      ],
    },
    {
      name: 'Reporte de ventas',
      pathname: '/sales-reports',
      icon: <Description />,
      roles: ['admin', 'dealer', 'gm', 'callcenter'],
      page: (
        <Suspense fallback={<LoadingPlaceholder />}>
          <SalesReports />
        </Suspense>
      ),
    },
    {
      name: 'Call Center',
      pathname: '/call-center',
      icon: <CallEnd />,
      roles: ['callcenter'],
      subroutes: [
        {
          name: 'Tareas pendientes',
          pathname: '/tasks',
          page: (
            <Suspense fallback={<LoadingPlaceholder />}>
              <Tasks />
            </Suspense>
          ),
        },
        {
          name: 'Carga de Fuentes',
          pathname: '/uploads/source',
          page: (
            <Suspense fallback={<LoadingPlaceholder />}>
              <SourceUpload />
            </Suspense>
          ),
        },
        {
          name: 'Descargar Leads',
          pathname: '/siebel/downloads',
          page: (
            <Suspense fallback={<LoadingPlaceholder />}>
              <SiebelLeads />
            </Suspense>
          ),
        },
      ],
    },
    {
      name: 'SIEBEL',
      pathname: '/siebel',
      icon: <Assignment />,
      roles: ['admin'],
      subroutes: [
        {
          name: 'Descargar Leads',
          pathname: '/siebel/downloads',
          page: (
            <Suspense fallback={<LoadingPlaceholder />}>
              <SiebelLeads />
            </Suspense>
          ),
        },
        {
          name: 'Generar reportes',
          pathname: '/siebel/reports',
          page: (
            <Suspense fallback={<LoadingPlaceholder />}>
              <SiebelReports />
            </Suspense>
          ),
        },
      ],
    },
    {
      name: 'Objetivos SIEBEL',
      pathname: '/siebel-objectives',
      icon: <TrackChanges />,
      roles: ['admin'],
      page: (
        <Suspense fallback={<LoadingPlaceholder />}>
          <SiebelObjectives />
        </Suspense>
      ),
    },
    {
      name: 'Consultar Leads',
      pathname: '/search-leads',
      icon: <FindInPage />,
      roles: ['admin', 'dealer', 'supervisor_dealer', 'callcenter_dealer'],
      page: (
        <Suspense fallback={<LoadingPlaceholder />}>
          <SearchLeads />
        </Suspense>
      ),
    },
    {
      name: 'Reporte leads por formulario',
      pathname: '/report-leads-by-form',
      icon: <TrackChanges />,
      roles: ['admin'],
      page: (
        <Suspense fallback={<LoadingPlaceholder />}>
          <LeadsByForm />
        </Suspense>
      ),
    },
    {
      pathname: '/inxait',
      icon: <DynamicFeed />,
      roles: ['admin', 'inxait'],
      name: 'Inxait',
      page: (
        <Suspense fallback={<LoadingPlaceholder />}>
          <Inxait />
        </Suspense>
      ),
    },
    {
      pathname: '/inxait-events',
      icon: <EventNote />,
      roles: ['admin', 'inxait'],
      name: 'Confirmaciones',
      page: (
        <Suspense fallback={<LoadingPlaceholder />}>
          <InxaitEvents />
        </Suspense>
      ),
    },
    {
      name: 'Configuraciones',
      pathname: '/config',
      icon: <Settings />,
      roles: ['admin'],
      subroutes: [
        {
          name: 'Modelos',
          pathname: '/models',
          page: (
            <Suspense fallback={<LoadingPlaceholder />}>
              <Models />
            </Suspense>
          ),
        },
        {
          name: 'Segmentos',
          pathname: '/segments',
          page: (
            <Suspense fallback={<LoadingPlaceholder />}>
              <Segments />
            </Suspense>
          ),
        },
        {
          name: 'Puntos de venta',
          pathname: '/dealers',
          page: (
            <Suspense fallback={<LoadingPlaceholder />}>
              <Dealers />
            </Suspense>
          ),
        },
        {
          name: 'Dealers por ciudad',
          pathname: '/dealer-cities',
          page: (
            <Suspense fallback={<LoadingPlaceholder />}>
              <DealerCities />
            </Suspense>
          ),
        },
        {
          name: 'Grupos Dealer',
          pathname: '/dealer-groups',
          page: (
            <Suspense fallback={<LoadingPlaceholder />}>
              <DealerGroups />
            </Suspense>
          ),
        },
        {
          name: 'Usuarios',
          pathname: '/users',
          page: (
            <Suspense fallback={<LoadingPlaceholder />}>
              <Users />
            </Suspense>
          ),
        },
        {
          name: 'Ciudades',
          pathname: '/cities',
          page: (
            <Suspense fallback={<LoadingPlaceholder />}>
              <Cities />
            </Suspense>
          ),
        },
        {
          name: 'Zonas',
          pathname: '/zones',
          page: (
            <Suspense fallback={<LoadingPlaceholder />}>
              <Zones />
            </Suspense>
          ),
        },
        {
          name: 'Anuncios',
          pathname: '/announcements',
          page: (
            <Suspense fallback={<LoadingPlaceholder />}>
              <Announcements />
            </Suspense>
          ),
        },
      ],
    },
    {
      name: 'Preguntas Frecuentes',
      pathname: '/faq',
      icon: <HelpOutline />,
      roles: ['admin', 'dealer', 'gm', 'callcenter'],
      page: (
        <Suspense fallback={<LoadingPlaceholder />}>
          <Faq />
        </Suspense>
      ),
    },
    {
      name: 'Accesos Diarios',
      pathname: '/daily-access',
      icon: <Assessment />,
      roles: ['admin', 'gm'],
      page: (
        <Suspense fallback={<LoadingPlaceholder />}>
          <DailyAccess />
        </Suspense>
      ),
    },
    {
      name: 'Reportes',
      pathname: '/reports',
      icon: <Timeline />,
      roles: ['admin', 'dealer', 'gm'],
      subroutes: [
        {
          name: 'Funnel Digital',
          pathname: '/reports/digital-funnel',
          page: (
            <Suspense fallback={<LoadingPlaceholder />}>
              <T1Funnel />
            </Suspense>
          ),
        },
        {
          name: 'Funnel Digital DDP',
          pathname: '/reports/digital-funnel-ddp',
          page: (
            <Suspense fallback={<LoadingPlaceholder />}>
              <DdpFunnel />
            </Suspense>
          ),
        },
        {
          name: 'Resumen Funnel',
          pathname: '/reports/summary',
          page: (
            <Suspense fallback={<LoadingPlaceholder />}>
              <DealersFunnelSummary />
            </Suspense>
          ),
        },
        {
          name: 'Análisis Overall',
          pathname: '/reports/overall',
          page: (
            <Suspense fallback={<LoadingPlaceholder />}>
              <OverallAnalysis />
            </Suspense>
          ),
        },
        {
          name: 'Franchise',
          pathname: '/reports/franchise',
          page: (
            <Suspense fallback={<LoadingPlaceholder />}>
              <FranchiseReport />
            </Suspense>
          ),
        },
        ...(isChileOrPeru
          ? String(role) !== 'dealer'
            ? [
                {
                  name: 'Leads Diarios',
                  pathname: '/reports/daily-leads',
                  page: (
                    <Suspense fallback={<LoadingPlaceholder />}>
                      <DailyLeads />
                    </Suspense>
                  ),
                },
              ]
            : []
          : [
              {
                name: 'Leads Diarios',
                pathname: '/reports/daily-leads',
                page: (
                  <Suspense fallback={<LoadingPlaceholder />}>
                    <DailyLeads />
                  </Suspense>
                ),
              },
            ]),
        ...(isChileOrPeru
          ? String(role) !== 'dealer'
            ? [
                {
                  name: 'Funnel Live Store',
                  pathname: '/reports/funnel-live-store',
                  page: (
                    <Suspense fallback={<LoadingPlaceholder />}>
                      <FunnelLiveStore />
                    </Suspense>
                  ),
                },
              ]
            : []
          : [
              {
                name: 'Funnel Live Store',
                pathname: '/reports/funnel-live-store',
                page: (
                  <Suspense fallback={<LoadingPlaceholder />}>
                    <FunnelLiveStore />
                  </Suspense>
                ),
              },
            ]),
        ...(String(role) === 'admin' || String(role) === 'gm'
          ? [
              {
                name: 'Leads Por País',
                pathname: '/reports/leads-per-country',
                roles: ['nodo'],
                page: (
                  <Suspense fallback={<LoadingPlaceholder />}>
                    <LeadsPerCountry />
                  </Suspense>
                ),
              },
            ]
          : []),
      ],
    },
    {
      name: 'Horarios de Expertos',
      pathname: '/expert-calendars',
      icon: <Today />,
      roles: ['admin'],
      page: (
        <Suspense fallback={<LoadingPlaceholder />}>
          <ExpertCalendars />
        </Suspense>
      ),
    },
  ];
}

export const getRouteName = (pathname: string) => {
  const routes = CreateRoutes();

  if (pathname === '/') {
    return 'Inicio';
  }
  return (
    routes.find((r) =>
      r.pathname === '/' ? false : pathname.indexOf(r.pathname) >= 0
    ) || { name: 'Inicio' }
  ).name;
};

export const useRoutes = (role: string) => {
  const routes = CreateRoutes();

  return useMemo(() => {
    const filteredRoutes = routes.filter((r) => {
      if (!r.roles || r.roles.length === 0) {
        return false;
      }
      return r.roles.indexOf(role) >= 0;
    });
    const flatRoutes: SimpleRoute[] = filteredRoutes.reduce(
      (res: SimpleRoute[], route: SimpleRoute | CompoundRoute) => {
        if ((route as CompoundRoute).subroutes) {
          return [...res, ...(route as CompoundRoute).subroutes];
        } else {
          return [...res, route as SimpleRoute];
        }
      },
      []
    );
    return { routes: filteredRoutes, flatRoutes };
    // eslint-disable-next-line
  }, [role]);
};
