import React from 'react';
import { useAuth } from 'containers/AuthProvider';
import { createRequestMethods } from './api';
import { AxiosError } from 'axios';
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';

type ApiState = {
  errorCode: number | null;
  status: 'idle' | 'error';
};
const NoResponseCode = 700;
const ApiContext = React.createContext<any>({});

const ErrorMessagesMap = new Map<number | null, string>([
  [
    NoResponseCode,
    'Parece que has perdido conexión al internet. Intenta más tarde',
  ],
  [401, 'Tu sesión ha finalizado. Ingresa nuevamente'],
  [403, 'No puedes realizar esa operación. Ponte en contacto con soporte.'],
  [404, 'No podemos encontrar lo que nos solicitaste. '],
  [409, 'Hay un error con la información que nos proporcionaste'],
  [500, 'Hubo un error procesando tu solicitud. Por favor intenta más tarde'],
  [null, 'Hubo un error procesando tu solicitud. Por favor intenta más tarde'],
]);

export function ApiProvider({ children }: { children: React.ReactNode }) {
  const [state, setState] = React.useState<ApiState>({
    status: 'idle',
    errorCode: null,
  });
  const { token, refreshToken, refresh, logout } = useAuth();

  function handleClose() {
    setState({ errorCode: null, status: 'idle' });
  }
  const value = React.useMemo(() => {
    function handleError(error: AxiosError) {
      if (error.response) {
        // returned from api buy with an error code outside 2xx
        setState({ status: 'error', errorCode: error.response.status });
        if (error.response.status === 401) {
          //When 401 is the response status the user is no longer authorized and should
          //be logged out
          logout();
        }
      } else if (error.response) {
        setState({ errorCode: NoResponseCode, status: 'error' });
      }
    }

    function handleTokenRefresh(token: string, refreshToken: string) {
      refresh(token, refreshToken);
    }
    return createRequestMethods(
      token,
      refreshToken,
      handleError,
      handleTokenRefresh
    );
  }, [logout, refresh, refreshToken, token]);
  const errorMessage = ErrorMessagesMap.get(state.errorCode);
  return (
    <ApiContext.Provider value={value}>
      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        open={state.status === 'error' && errorMessage !== undefined}
        autoHideDuration={6000}
        onClose={handleClose}
      >
        <Alert onClose={handleClose} severity="error">
          {errorMessage}
        </Alert>
      </Snackbar>
      {children}
    </ApiContext.Provider>
  );
}

export function useApi() {
  const context = React.useContext(ApiContext);
  if (!context) {
    throw new Error('useApi should be used inside ApiProvider');
  }
  return context;
}
