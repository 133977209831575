export type AuthState = {
  status: 'unauthenticated' | 'authenticated';
  role?: 'admin' | 'user' | 'callcenter' | 'inxait' | 'gm';
  token: string;
  username: string;
  dealerGroupId: string | null;
  refreshToken: string;
  areSoonToExpire: boolean;
  areExpired: boolean;
  credentialsExpireAt: Date;
};

export type AuthActions =
  | {
      type: 'login';
      role: AuthState['role'];
      token: string;
      refreshToken: string;
      username: string;
      dealerGroupId: string | null;
      areSoonToExpire: boolean;
      areExpired: boolean;
      credentialsExpireAt: Date;
    }
  | { type: 'logout' }
  | { type: 'refresh'; token: string; refreshToken: string };

const reducer = (state: AuthState, action: AuthActions): AuthState => {
  switch (action.type) {
    case 'login':
      return {
        ...state,
        status: 'authenticated',
        role: action.role,
        token: action.token,
        refreshToken: action.refreshToken,
        username: action.username,
        dealerGroupId: action.dealerGroupId,
        areSoonToExpire: action.areSoonToExpire,
        areExpired: action.areExpired,
        credentialsExpireAt: action.credentialsExpireAt,
      };
    case 'logout':
      return {
        ...state,
        status: 'unauthenticated',
        role: undefined,
      };
    case 'refresh':
      return {
        ...state,
        status: 'authenticated',
        token: action.token,
        refreshToken: action.refreshToken,
      };
    default:
      return state;
  }
};

export default reducer;
