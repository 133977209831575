import React from 'react';
import reducer, { AuthState, AuthActions } from './reducer';

// SK stands for STORAGE KEY
const TOKEN_SK = 'token';
const REFRESH_TOKEN_SK = 'refresh';
const ROLE_SK = 'role';
const USERNAME_SK = 'username';
const DEALER_SK = 'dealerGroupId';
const ARE_SOON_TO_EXPIRE_SK = 'areSoonToExpire';
const ARE_EXPIRED_SK = 'areExpired';
const CREDENTIALS_EXPIRE_AT_SK = 'credentialsExpireAt';

const getInitialState = (): AuthState => {
  try {
    const token = window.localStorage.getItem(TOKEN_SK);
    const refreshToken = window.localStorage.getItem(REFRESH_TOKEN_SK);
    const role = window.localStorage.getItem(ROLE_SK) as AuthState['role'];
    const username = window.localStorage.getItem(USERNAME_SK);
    const dealerGroupId = window.localStorage.getItem(DEALER_SK);
    const areSoonToExpire =
      window.localStorage.getItem(ARE_SOON_TO_EXPIRE_SK) === 'true';
    const areExpired = window.localStorage.getItem(ARE_EXPIRED_SK) === 'true';
    const credentialsExpireAt =
      window.localStorage.getItem(CREDENTIALS_EXPIRE_AT_SK) || new Date();
    if (token && refreshToken && role && username) {
      return {
        status: 'authenticated',
        role: role,
        token,
        refreshToken,
        username,
        dealerGroupId,
        areSoonToExpire,
        areExpired,
        credentialsExpireAt: new Date(credentialsExpireAt.toString()),
      };
    } else
      return {
        status: 'unauthenticated',
        role: undefined,
        token: '',
        refreshToken: '',
        username: '',
        dealerGroupId: null,
        areSoonToExpire: false,
        areExpired: false,
        credentialsExpireAt: new Date(),
      };
  } catch (e) {
    return {
      status: 'unauthenticated',
      role: undefined,
      token: '',
      refreshToken: '',
      username: '',
      dealerGroupId: null,
      areSoonToExpire: false,
      areExpired: false,
      credentialsExpireAt: new Date(),
    };
  }
};

function saveInLocalStorage(
  role: AuthState['role'],
  token: string,
  refreshToken: string,
  username: string,
  dealerGroupId: string,
  areSoonToExpire: boolean,
  areExpired: boolean,
  credentialsExpireAt: Date
) {
  window.localStorage.setItem(ROLE_SK, `${role}`);
  window.localStorage.setItem(TOKEN_SK, token);
  window.localStorage.setItem(REFRESH_TOKEN_SK, refreshToken);
  window.localStorage.setItem(USERNAME_SK, username);
  window.localStorage.setItem(DEALER_SK, dealerGroupId);
  window.localStorage.setItem(
    ARE_SOON_TO_EXPIRE_SK,
    areSoonToExpire.toString()
  );
  window.localStorage.setItem(ARE_EXPIRED_SK, areExpired.toString());
  window.localStorage.setItem(
    CREDENTIALS_EXPIRE_AT_SK,
    credentialsExpireAt.toString()
  );
}

function clearStorage() {
  window.localStorage.removeItem(ROLE_SK);
  window.localStorage.removeItem(TOKEN_SK);
  window.localStorage.removeItem(REFRESH_TOKEN_SK);
  window.localStorage.removeItem(USERNAME_SK);
  window.localStorage.removeItem(DEALER_SK);
  window.localStorage.removeItem(ARE_SOON_TO_EXPIRE_SK);
  window.localStorage.removeItem(ARE_EXPIRED_SK);
  window.localStorage.removeItem(CREDENTIALS_EXPIRE_AT_SK);
}

const AuthContext = React.createContext<
  [AuthState, React.Dispatch<AuthActions>]
>([getInitialState(), () => {}]);
AuthContext.displayName = 'AuthContext';

export function AuthProvider(props: any) {
  const [state, dispatch] = React.useReducer(reducer, getInitialState());
  // Use React.useMemo to make sure the value passed to AuthContext.Provider does not update unless
  // the state changes, which means that some action was disppatched
  // If we passed value={[state,dispatch]} directly, AuthContext.Provider would trigger
  // re-renders even when the state hasn't been updated
  const value = React.useMemo(() => [state, dispatch], [state]);
  return <AuthContext.Provider {...props} value={value} />;
}

export function useAuth() {
  const context = React.useContext(AuthContext);
  if (!context) {
    throw new Error('useAuth should needs to be used inside AuthProvider');
  }
  const [state, dispatch] = context;
  // useMemo avoid new values to be created when the hook is executed again
  // so for instance if the exposed refresh function is used somewhere else
  // it will be the same until something in state changes
  const login = React.useCallback(
    (
      role: AuthState['role'],
      token: string,
      refreshToken: string,
      username: string,
      dealerGroupId: string,
      areSoonToExpire: boolean,
      areExpired: boolean,
      credentialsExpireAt: Date
    ) => {
      saveInLocalStorage(
        role,
        token,
        refreshToken,
        username,
        dealerGroupId,
        areSoonToExpire,
        areExpired,
        credentialsExpireAt
      );
      dispatch({
        type: 'login',
        role,
        token,
        refreshToken,
        username,
        dealerGroupId,
        areSoonToExpire,
        areExpired,
        credentialsExpireAt,
      });
    },
    [dispatch]
  );

  const logout = React.useCallback(() => {
    clearStorage();
    dispatch({ type: 'logout' });
  }, [dispatch]);

  const refresh = React.useCallback(
    (token: string, refreshToken: string) =>
      dispatch({ type: 'refresh', token, refreshToken }),
    [dispatch]
  );

  const value = React.useMemo(
    () => ({
      authenticated: state.status === 'authenticated',
      role: state.role,
      token: state.token,
      refreshToken: state.refreshToken,
      username: state.username,
      dealerGroupId: state.dealerGroupId,
      areSoonToExpire: state.areSoonToExpire,
      areExpired: state.areExpired,
      credentialsExpireAt: state.credentialsExpireAt,
      login,
      logout,
      refresh,
    }),
    [
      login,
      logout,
      refresh,
      state.refreshToken,
      state.role,
      state.status,
      state.token,
      state.username,
      state.dealerGroupId,
      state.areSoonToExpire,
      state.areExpired,
      state.credentialsExpireAt,
    ]
  );
  return value;
}
