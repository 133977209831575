import React from 'react';
import AppBar from '@material-ui/core/AppBar';
import Drawer from '@material-ui/core/Drawer';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import clsx from 'clsx';
import { ChevronLeft } from '@material-ui/icons';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import logo from 'images/logo.png';

const instanceName = process.env.REACT_APP_GM_INSTANCE_NAME;

type LayoutProps = {
  drawerContent: (
    openDrawer: () => void,
    drawerOpened: boolean
  ) => React.ReactNode;
  toolbarActions: React.ReactNode;
  children: React.ReactNode;
  routeName: string;
};

const drawerWidth = 320;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      position: 'fixed',
      width: '100vw',
      height: '100vh',
      overflow: 'auto',
    },
    menuButton: {
      marginRight: theme.spacing(3),
    },
    instanceName: {
      ...theme.typography.caption,
      color: theme.palette.text.hint,
      flex: 1,
      textAlign: 'start',
      alignSelf: 'flex-end',
      marginBottom: theme.spacing(1),
    },
    routeName: {
      flexGrow: 1,
    },
    content: {
      display: 'flex',
      flexGrow: 1,
      flexDirection: 'column',
      minHeight: '100vh',
      maxWidth: `calc(100% - ${theme.spacing(7) + 1}px)`,
    },
    logo: {
      background: `url(${logo})`,
      backgroundSize: 'contain',
      backgroundRepeat: 'no-repeat',
      height: `calc(100% - ${theme.spacing(2)}px)`,
      flex: '1 0',
    },
    appBar: {
      zIndex: theme.zIndex.drawer + 1,
      transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
    },
    appBarShift: {
      marginLeft: drawerWidth,
      width: `calc(100% - ${drawerWidth}px)`,
      transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    drawer: {
      width: drawerWidth,
      flexShrink: 0,
      whiteSpace: 'nowrap',
    },
    drawerOpen: {
      width: drawerWidth,
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    drawerClose: {
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      overflowX: 'hidden',
      width: theme.spacing(7) + 1,
    },
    toolbar: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-end',
      padding: theme.spacing(0, 3),
      // necessary for content to be below app bar
      ...theme.mixins.toolbar,
    },
    drawerToolbar: {
      display: 'flex',
      alignItems: 'center',
      padding: theme.spacing(0, 0, 0, 2),
    },
    hide: {
      display: 'none',
    },
  })
);

export default function ResponsiveDrawer(props: LayoutProps) {
  const styles = useStyles();
  const [open, setOpen] = React.useState(false);

  const drawer = (
    <>
      <Toolbar className={styles.drawerToolbar}>
        <div className={styles.logo} />
        <span className={styles.instanceName}>{instanceName}</span>
        <IconButton onClick={() => setOpen(false)}>
          <ChevronLeft />
        </IconButton>
      </Toolbar>
      {/* Pass a function to open the drawer when the clicked object is a menu with sub menus inside */}
      {props.drawerContent(() => setOpen(true), open)}
    </>
  );

  return (
    <div className={styles.root}>
      <nav>
        <Drawer
          variant="permanent"
          className={clsx(styles.drawer, {
            [styles.drawerOpen]: open,
            [styles.drawerClose]: !open,
          })}
          classes={{
            paper: clsx({
              [styles.drawerOpen]: open,
              [styles.drawerClose]: !open,
            }),
          }}
        >
          {drawer}
        </Drawer>
      </nav>
      <AppBar
        position="fixed"
        className={clsx(styles.appBar, {
          [styles.appBarShift]: open,
        })}
      >
        <Toolbar className={styles.toolbar}>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={() => setOpen(true)}
            edge="start"
            className={clsx(styles.menuButton, {
              [styles.hide]: open,
            })}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" noWrap className={styles.routeName}>
            {props.routeName}
          </Typography>
          {props.toolbarActions}
        </Toolbar>
      </AppBar>
      <main className={styles.content}>
        {/* Place an empty toolbar to push the content down */}
        <div className={styles.toolbar} />
        {props.children}
      </main>
    </div>
  );
}
