import React from 'react';
import { InfoOutlined } from '@material-ui/icons';
import { Box, Typography, Button } from '@material-ui/core';
import { useHistory } from 'react-router-dom';

function NotFound() {
  const { replace } = useHistory();
  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      flex={1}
      padding={4}
    >
      <InfoOutlined style={{ fontSize: 64 }} />
      <Typography variant="h4" style={{ marginBottom: 16 }}>
        No hay nada aquí
      </Typography>
      <Typography align="center">
        Parece que la página que estás buscando no existe
      </Typography>
      <Box marginTop={2}>
        <Button
          variant="contained"
          size="large"
          color="primary"
          onClick={() => replace('/home')}
        >
          Regresar
        </Button>
      </Box>
    </Box>
  );
}

export default NotFound;
