import React from 'react';
import Box from '@material-ui/core/Box';
import CircularProgress from '@material-ui/core/CircularProgress';

export default function LoadingPlaceholder() {
  return (
    <Box
      display="flex"
      height="100%"
      flexGrow={1}
      alignItems="center"
      justifyContent="center"
    >
      <CircularProgress />
    </Box>
  );
}
