import React, { Component } from 'react';
import InfoOutlined from '@material-ui/icons/InfoOutlined';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import * as Sentry from '@sentry/browser';

class ErrorBoundary extends Component<
  {},
  { hasError: boolean; errorMessage: string | null }
> {
  constructor(props: any) {
    super(props);
    Sentry.init({ dsn: process.env.REACT_APP_SENTRY_DSN });
    this.state = {
      hasError: false,
      errorMessage: '',
    };
  }

  static getDerivedStateFromError(error: Error) {
    return { hasError: true, errorMessage: error.message };
  }

  componentDidCatch(error: any, errorInfo: any) {
    Sentry.withScope((scope) => {
      scope.setExtras(errorInfo);
      Sentry.captureException(error);
    });
  }

  handleClose = () => {
    this.setState({ hasError: false });
  };

  render() {
    if (this.state.hasError) {
      return (
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          height="100vh"
          width="100vw"
          padding={4}
        >
          <InfoOutlined color="error" style={{ fontSize: 64 }} />
          <Typography variant="h4" style={{ marginBottom: 16 }}>
            Algo salió mal
          </Typography>
          <Typography align="center">
            Ocurrió un error mientras utilizabas la página. Por favor intenta
            más tarde
          </Typography>
          <Box marginTop={2}>
            <Button
              onClick={this.handleClose}
              variant="contained"
              size="large"
              color="primary"
            >
              Continuar
            </Button>
          </Box>
        </Box>
      );
    }
    return this.props.children;
  }
}

export default ErrorBoundary;
