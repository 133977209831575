import React from 'react';
import { TextField } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { Form, Formik, Field, FieldProps } from 'formik';
import { object, string } from 'yup';

const loginSchema = object().shape({
  username: string().required('Ingrese su usuario'),
  password: string().required('Ingrese su contraseña'),
});

type LoginFormProps = {
  onSubmit: (username: string, password: string) => void;
  renderButtons: () => React.ReactNode;
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    form: {
      display: 'flex',
      flexDirection: 'column',
    },
    buttons: {
      marginTop: theme.spacing(2),
    },
    textField: {
      marginTop: theme.spacing(2),
    },
  })
);

function LoginForm({ onSubmit, renderButtons }: LoginFormProps) {
  const styles = useStyles();
  return (
    <Formik
      validateOnBlur={false}
      validateOnChange={false}
      validationSchema={loginSchema}
      initialValues={{ username: '', password: '' }}
      onSubmit={(values) => onSubmit(values.username, values.password)}
    >
      {({ errors }) => (
        <Form className={styles.form}>
          <Field name="username">
            {({ field }: FieldProps) => (
              <TextField
                className={styles.textField}
                label="Nombre de usuario"
                {...field}
                error={errors.username !== undefined}
                helperText={errors.username}
              />
            )}
          </Field>
          <Field name="password">
            {({ field }: FieldProps) => (
              <TextField
                className={styles.textField}
                label="Contraseña"
                {...field}
                type="password"
                error={errors.password !== undefined}
                helperText={errors.password}
              />
            )}
          </Field>
          <div className={styles.buttons}>{renderButtons()}</div>
        </Form>
      )}
    </Formik>
  );
}

export default LoginForm;
