import React, { lazy, Suspense } from 'react';
import {
  Switch,
  Route,
  Redirect,
  useLocation,
  Link,
  useHistory,
} from 'react-router-dom';
import { useAuth } from 'containers/AuthProvider';
import {
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Collapse,
  Tooltip,
} from '@material-ui/core';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { ExpandLess, ExpandMore } from '@material-ui/icons';
import ResponsiveLayout from './ResponsiveLayout';
import { useRoutes, getRouteName, CompoundRoute, SimpleRoute } from './routes';
import UserMenu from './UserMenu';

// Import Login directly
import LoginPage from 'pages/Login';
import NotFound from 'pages/NotFound';

import LoadingPlaceholder from './LoadingPlaceholder';
const Profile = lazy(() => import('pages/Profile'));

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    nested: {
      paddingLeft: theme.spacing(4),
    },
  })
);
function Routes() {
  const { authenticated, logout, username, role, areSoonToExpire } = useAuth();
  const { routes, flatRoutes } = useRoutes(role || '');
  const {
    pathname,
    state = { from: areSoonToExpire ? '/profile' : '/home' },
  } = useLocation();
  const { push } = useHistory();
  const routeName = getRouteName(pathname);
  const [openedMenu, setOpenedMenu] = React.useState(routeName);
  const styles = useStyles();
  return (
    <Switch>
      <Route path="/login">
        {authenticated && <Redirect to={{ pathname: state.from || '/home' }} />}
        <LoginPage />
      </Route>
      <Route path="/" exact>
        {authenticated && <Redirect to={{ pathname: state.from || '/home' }} />}
        <LoginPage />
      </Route>
      <Route path="/">
        {!authenticated && (
          <Redirect
            to={{
              pathname: '/login',
              state: { from: pathname === '/' ? '/home' : pathname },
            }}
          />
        )}
        <ResponsiveLayout
          routeName={routeName}
          toolbarActions={
            <>
              <UserMenu
                logout={logout}
                username={username}
                goToProfile={() => push('/profile')}
              ></UserMenu>
            </>
          }
          drawerContent={(openDrawer, drawerOpened) => (
            <List>
              {routes.map((r) => {
                if ((r as CompoundRoute).subroutes) {
                  // In this case, the route contains subroutes and should be a desplegable menu
                  return (
                    <React.Fragment key={r.name}>
                      <ListItem
                        button
                        onClick={() => {
                          // Open the drawer so the options are visible
                          openDrawer();
                          setOpenedMenu((om) => (om === r.name ? '' : r.name));
                        }}
                      >
                        <ListItemIcon>
                          <Tooltip title={r.name} placement="right">
                            {r.icon || <div />}
                          </Tooltip>
                        </ListItemIcon>
                        <ListItemText primary={r.name} />
                        {/* If the drawer is opened, all menus should be closed */}
                        {openedMenu === r.name && drawerOpened ? (
                          <ExpandLess />
                        ) : (
                          <ExpandMore />
                        )}
                      </ListItem>
                      <Collapse
                        in={openedMenu === r.name && drawerOpened}
                        timeout="auto"
                        unmountOnExit
                      >
                        <List component="div" disablePadding>
                          {(r as CompoundRoute).subroutes.map((subroute) => (
                            <Link
                              style={{
                                textDecoration: 'none',
                                color: 'inherit',
                              }}
                              key={subroute.name}
                              to={{
                                pathname: subroute.pathname,
                                state: { name: subroute.name },
                              }}
                            >
                              <ListItem button className={styles.nested}>
                                <ListItemText primary={subroute.name} />
                              </ListItem>
                            </Link>
                          ))}
                        </List>
                      </Collapse>
                    </React.Fragment>
                  );
                } else {
                  return (
                    <Link
                      style={{
                        textDecoration: 'none',
                        color: 'inherit',
                      }}
                      key={r.name}
                      to={{ pathname: r.pathname, state: { name: r.name } }}
                    >
                      <ListItem button>
                        <ListItemIcon>
                          <Tooltip title={r.name} placement="right">
                            {r.icon || <div />}
                          </Tooltip>
                        </ListItemIcon>
                        <ListItemText primary={r.name} />
                      </ListItem>
                    </Link>
                  );
                }
              })}
            </List>
          )}
        >
          <Switch>
            {flatRoutes.map((r) => (
              <Route path={r.pathname} key={r.name} exact={r.pathname === '/'}>
                {(r as SimpleRoute).page}
              </Route>
            ))}
            <Route path="/profile">
              <Suspense fallback={<LoadingPlaceholder />}>
                <Profile />
              </Suspense>
            </Route>
            <Route path="*">
              <NotFound />
            </Route>
          </Switch>
        </ResponsiveLayout>
      </Route>
    </Switch>
  );
}

export default Routes;
