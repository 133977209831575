import { useReducer, useEffect, useMemo, useCallback } from 'react';
import { useLocation, useHistory, useRouteMatch } from 'react-router-dom';
import { object, string, number } from 'yup';
import qs from 'qs';
import { useApi } from 'containers/ApiProvider';
import { add, format } from 'date-fns';

const querySchema = object().shape({
  page: number().min(0),
  size: number().min(5),
  search: string(),
});

type State = {
  status: 'loading' | 'idle' | 'error';
  tasks: any[];
  hasDelayedTasks: boolean;

  query: {
    page: number;
    size: number;
    search: string;
  };
  error: null | string;
};

type Actions =
  | { type: 'load'; results: any[]; hasDelayedTasks: boolean }
  | { type: 'mark-as-pending' }
  | {
      type: 'error';
      error: string;
    }
  | {
      type: 'query-update';
      field: keyof State['query'];
      value: any;
    };

const reducer = (state: State, action: Actions): State => {
  switch (action.type) {
    case 'load':
      return {
        ...state,
        status: 'idle',
        hasDelayedTasks: action.hasDelayedTasks,
        tasks: action.results,
      };
    case 'mark-as-pending':
      return {
        ...state,
        status: 'loading',
      };
    case 'error':
      return { ...state, status: 'error', error: action.error };
    case 'query-update':
      return {
        ...state,
        status: 'loading',
        query: {
          ...state.query,
          [action.field]: action.value,
        },
      };
    default:
      return state;
  }
};

const defaultQuery: State['query'] = {
  page: 1,
  size: 5,
  search: '',
};

// Get the search portion of the location, validate it
// and return the values if validation is successful
function parseUrlQuery(query: string): State['query'] {
  try {
    const fromUrl = querySchema.cast(qs.parse(query));
    querySchema.validateSync(fromUrl);
    return { ...defaultQuery, ...(fromUrl as State['query']) };
  } catch (e) {
    return defaultQuery;
  }
}

function getModifiedFields(query: State['query']): Partial<State['query']> {
  const result: Partial<State['query']> = {};
  if (query.search !== defaultQuery.search) {
    result.search = query.search;
  }
  if (query.size !== defaultQuery.size) {
    result.size = query.size;
  }
  if (query.page !== defaultQuery.page) {
    result.page = query.page;
  }
  return result;
}

export function useTasksTable() {
  const { search } = useLocation();
  const { url } = useRouteMatch();
  const { get, post } = useApi();
  const { replace } = useHistory();
  const initialState: State = useMemo(
    () => ({
      status: 'loading',
      total: 0,
      tasks: [],
      hasDelayedTasks: false,
      error: null,
      query: parseUrlQuery(search.replace('?', '')),
    }),
    [search]
  );
  const [state, dispatch] = useReducer(reducer, initialState);
  const queryTasks = useCallback(
    async function queryTasks(query: State['query']) {
      try {
        const tasks = await get(`/tasks/dealer-live-store`);
        let hasDelayedTasks = false;
        const dateFormat = 'd/M/yyyy p';
        const withParsedFields = tasks.map((task: any) => {
          // createdAt and updatedAt dates are 5 hours into the future
          const updatedAt = add(new Date(task.updatedAt), { hours: -5 });
          const createdAt = add(new Date(task.createdAt), { hours: -5 });
          // wether the task has taken too long to process and can be re-schedule for processing
          // from the table UI. That is, when 1 hour have passed from the last update and state is still EJECUTANDO
          const isDelayed =
            new Date().getTime() - updatedAt.getTime() > 1000 * 60 * 60 * 1 &&
            task.status === 'EJECUTANDO';
          if (isDelayed) hasDelayedTasks = true;

          return {
            ...task,
            createdAt: format(createdAt, dateFormat),
            updatedAt: format(updatedAt, dateFormat),
            isDelayed,
            filePath: (task.filePath || '').split('/').pop(),
          };
        });
        dispatch({
          type: 'load',
          results: withParsedFields,
          hasDelayedTasks,
        });
        replace(`${url}?${qs.stringify(getModifiedFields(query))}`);
      } catch (e) {
        console.log(e);
        dispatch({ type: 'error', error: e.message });
      }
    },
    [get, replace, url]
  );
  async function markAsPending(id: number) {
    try {
      await post(`/tasks/${id}/mark-as-pending`);
    } catch (e) {
      dispatch({ type: 'error', error: e.message });
    }
    await queryTasks(state.query);
  }
  useEffect(() => {
    queryTasks(state.query);
  }, [queryTasks, state.query]);
  function reload() {
    queryTasks(state.query);
  }

  return {
    loading: state.status === 'loading',
    hasError: state.status === 'error',
    error: state.error,
    tasks: state.tasks,
    query: state.query,
    hasDelayedTasks: state.hasDelayedTasks,
    markAsPending,
    reload,
    updateQuery: (field: keyof State['query'], value: any) =>
      dispatch({ type: 'query-update', field, value }),
  };
}
